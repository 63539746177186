<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">补助金</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>

    <div class="allowance">
      <div class="top"></div>
      <div class="bottom">我的补助金：{{ allowance }}</div>
    </div>
    <div style="height:160px"></div>
    <div v-if="list && list.length > 0" class="card">
      <van-list
        v-if="list && list.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-row v-for="(item, index) in list" :key="index" class="list">
          <van-col span="18" style="color:rgba(51,51,51);">{{ item.createTime }}</van-col>
          <van-col
            v-if="item.status === 1"
            span="6"
            class="money-add"
          >+{{ getMoney(item.amount / 100) }}</van-col>
          <van-col
            v-if="item.status === 2"
            span="6"
            class="money-sub"
          >-{{ getMoney(item.amount / 100) }}</van-col>
        </van-row>
      </van-list>
    </div>
    <van-empty v-else description="暂无记录" style="height: calc(100vh - 160px);background: #ffffff;" />
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { currencyTransform, getMoney } from "@/utils/util";
import { allowanceList } from "@/api/allowance";

export default {
  name: "Allowance",
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      cancel,
      allowance: "",
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
      list: [],
    };
  },
  computed: {
    money() {
      return (fee) => {
        return currencyTransform(fee);
      };
    },
  },
  created() {
    const { $store, fetch } = this;
    this.allowance = (Number($store.getters.allowance) / 100).toFixed(2);
    fetch();
  },
  methods: {
    getMoney,
    onLoad() {
      const { current, size, total } = this.pagination;
      console.log(current, size, total);
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.pagination.current = this.pagination.current + 1;
        this.fetch();
      }
    },
    toHome() {
      this.$router.back();
    },
    async fetch() {
      const { pagination, list } = this;
      this.show = true;
      const { data, code, message } = await allowanceList(pagination);
      if (code === 0) {
        this.list = [...list, ...data.records];
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        setTimeout(() => {
          this.loading = false;
          this.show = false;
        }, 500);
      } else {
        console.log(message);
      }
    },
    toBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background: #ffffff;
  & > .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    //position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .allowance {
    position: fixed;
    width: 100%;
    top: 48px;
    font-size: 1.3rem;
    background: #ffffff;
    height: 112px;
    .bottom {
      margin: 0 auto;
      width: 95%;
      height: 70px;
      line-height: 70px;
      text-align: center;
      color: white;
      background: rgba(252, 103, 60);
      border-radius: 0.25rem;
      margin-top: 10px;
    }
    .top {
      height: 7px;
      width: 100%;
      background: rgba(238, 238, 238);
    }
  }
  .card {
    height: calc(100vh - 160px);
    background: #ffffff;
    overflow: auto;
    .list {
      height: 3rem;
      padding: 0 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
    }
    .money-add,
    .money-sub {
      text-align: right;
      color: rgba(245, 154, 35);
    }
  }
}
::v-deep .van-row {
  border-bottom: 1px solid #ccc;
  padding: 0.6rem 0;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
